import { Box, GridItem, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export default function CardMainService({ name, content, image }) {
  return (
    <GridItem overflow="hidden" rounded="md" bg="white" p="5" shadow="md" sx={{ "&:hover .change-icon": { color: "white", bg: "#273071" } }}>
      <Stack justifyContent="center" alignItems="center" rounded="full" spacing="5" py="10">
        <Box overflow="hidden" w="100px" h="100px" bg="#273071" rounded="full">
          <Image w="full" h="full" src={image} />
        </Box>
        <Text fontWeight="bold" textAlign="center" fontSize="lg">{name}</Text>
        <Text textAlign="center">{content}</Text>
      </Stack>
    </GridItem>
  )
}
