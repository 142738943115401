import { 
  Accordion, 
  AccordionButton, 
  AccordionItem, 
  AccordionPanel, 
  Box, Button, Container, 
  Flex, Grid, GridItem, Image, Input, ListItem, Modal, ModalBody, ModalContent, ModalOverlay, OrderedList, Select, Stack, Text, Textarea, UnorderedList, useDisclosure } from "@chakra-ui/react";
import { AiOutlineClockCircle, AiOutlinePlayCircle } from "react-icons/ai"
import { FiMail } from "react-icons/fi"
import CardMainService from "./atoms/CardMainService";
import { data_dummy } from "./utils/data_dummy";
import {FaCheck, FaCoins, FaGlobe, FaMapMarkerAlt, FaMinus, FaPhoneVolume, FaPlus, FaStar, FaWhatsapp} from "react-icons/fa"
import {MdFormatQuote} from "react-icons/md"
import ReactPlayer from "react-player";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import { useState } from "react";
import emailJs from "@emailjs/browser";
import Swal from "sweetalert2";
import { useInput } from "./hooks/useInput";
import { Navigation, Autoplay, Pagination } from "swiper"

function App() {
  const [selectedGalery, setSelectedGalery] = useState('all')
  const [galery] = useState(() => data_dummy.galeri_transporter.data)

  const [selectOption, setSelectOption] = useState('')

  const namaLengkap = useInput('') //Nama_Lengkap;
  const tglLahir = useInput('') //Tanggal_Lahir;
  const perusahaan = useInput('') //Perusahaan;
  const alamat = useInput('') //Alamat;
  const email = useInput('') //Email;
  const noHandphone = useInput('') //No_Handphone;
  const pesan = useInput('') //Pesan;

  const handleSelectChange = (e) => {
    setSelectOption(e.target.value)
  }

  const sendEmail = (e) => {
    e.preventDefault()

    const templateParams = {
      Nama_Lengkap: namaLengkap.value,
      Tanggal_Lahir: tglLahir.value,
      Perusahaan: perusahaan.value,
      Alamat: alamat.value,
      Email: email.value,
      No_Handphone: noHandphone.value,
      Produk_Transporter: selectOption,
      Pesan: pesan.value,
    }

    emailJs
      .send(
        'service_lusnf82',
        'template_uvvkkla',
        templateParams,
        's1NiOPA5fi52VHvI4'
      ).then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Pesan Berhasil Terkirim',
          showConfirmButton: false,
          timer: 1500
        })
    
        namaLengkap.onReset()
        tglLahir.onReset()
        perusahaan.onReset()
        alamat.onReset()
        email.onReset()
        noHandphone.onReset()
        pesan.onReset()
      }, (error) => {
        
    })
  }
  
  const onChangeGalery = (type) => {
    setSelectedGalery(type)
  }

  const dataFiltered = galery.filter((gal) => gal.type.toLowerCase().includes(selectedGalery === 'all' ? '' : selectedGalery.toLowerCase()))

  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box height="96">
              <ReactPlayer width="100%" height="100%" controls={false} light={true} url="https://youtu.be/NTzTB25zyCs" />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box h="120vh" position="relative">
        <Image
          src="https://transporter.saranapatra.com/images/bannerTransporter01.jpg"
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="-1"
          objectFit="cover" />
        <Container maxW="6xl" py={{ base: "12", md: "28" }}>
          <Stack spacing="6">
            <Box>
              <Image src="https://transporter.saranapatra.com/images/SPJ-logo.png" />
            </Box>
            <Text fontSize="xl" color="white" fontWeight="semibold"># Transporter Limbah B3</Text>
            <Box w={{ base: "100%", xl: "60%" }}>
              <Text fontSize={{ base: '2xl', md: '4xl', xl: "5xl" }} color="white" fontWeight="bold">Percayakan Pengangkutan Limbah B3 Kepada Kami</Text>
            </Box>
            <Stack w={{ base: "100%", xl: "50%" }} spacing="4">
              <Text color="white" fontSize="xl">Kami menyediakan jasa pengangkutan limbah B3 medis & B3 industri dengan armada dan pengemudi terlatih bersertifikat dengan legalitas perijinan yang lengkap</Text>
              <Stack direction="row">
                <Button
                  onClick={() => window.open('https://wa.me/089616099449', '_blank')}
                  bg="#fb6a05"
                  color="white"
                  w="fit-content"
                  px="5"
                  py="7"
                  _hover={{ bg: 'white', color: '#fb6a05' }}>Hubungi Kami</Button>
                <Button
                  onClick={onOpen}
                  variant="ghost"
                  color="white"
                  w="fit-content"
                  px="5" py="7"
                  _hover={{ bg: "" }} _active={{ bg: 'transparent' }}>
                  <Stack direction="row" alignItems="center" spacing="0.5">
                    <AiOutlinePlayCircle size={30} />
                    <Box as="span">Lihat Video</Box>
                  </Stack>
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl" position="relative" transform="translateY(-5rem)">
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }} gap="6" top="-10" left="0" right="0">
            {
              data_dummy.mainService.map((ms, id) => (
                <CardMainService name={ms.name} content={ms.content} key={id} image={ms.image} />
              ))
            }
          </Grid>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Text fontWeight="bold" fontSize="3xl" color="#fb6a05">Apa itu Limbah B3 ?</Text>
          <Stack mt="5">
            <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap="10">
              <GridItem>
                <Text>Limbah B3 (Bahan Berbahaya dan Beracun) adalah jenis limbah yang memiliki sifat-sifat berbahaya dan beracun bagi lingkungan hidup dan manusia. Limbah B3 dapat berasal dari berbagai industri, seperti industri kimia, farmasi, elektronik, dan pertambangan, serta rumah tangga. Beberapa sifat yang membuat limbah B3 berbahaya dan beracun antara lain:</Text>
                <Box pl="3" pt="3">
                  <OrderedList>
                    <ListItem>
                      <Text>Sifat korosif, yaitu sifat limbah yang dapat merusak atau mengikis bahan-bahan yang berinteraksi dengan limbah tersebut, seperti logam, beton, atau kulit.</Text>
                    </ListItem>
                    <ListItem>
                      <Text>Sifat toksik, yaitu sifat limbah yang dapat menyebabkan keracunan atau kematian jika terkena manusia atau hewan, seperti logam berat, pestisida, atau bahan kimia beracun.</Text>
                    </ListItem>
                    <ListItem>
                      <Text>Sifat mudah terbakar atau meledak, yaitu sifat limbah yang dapat menyebabkan kebakaran atau ledakan jika terkena sumber panas atau gesekan.</Text>
                    </ListItem>
                    <ListItem>
                      <Text>Sifat biologis, yaitu sifat limbah yang dapat menimbulkan dampak buruk bagi kesehatan manusia atau lingkungan hidup karena adanya bahan-bahan organik atau mikroorganisme yang berbahaya.</Text>
                    </ListItem>
                  </OrderedList>
                </Box>
              </GridItem>
              <GridItem>
                <Stack spacing="10">
                  <Stack spacing="5">
                    <Text>Istilah kata “limbah berbahaya” mencakup semua cairan kimia beracun, limbah bahan radioaktif, (limbah B3 biologis atau limbah infeksius), juga limbah lainnya yang teridentifikasi mengandung bahan berbahaya berdasarkan tes laboratorium.</Text>
                    <Text>Hampir semua limbah berbahaya dihasilkan dari proses industrialisasi yang menghasilkan sisa-sisa hasil produksi yang tidak diinginkan, produk yang cacat, dan sisa bahan baku. Produksi dan pembuangan limbah berbahaya diatur oleh berbagai peraturan, baik luar negeri maupun dalam negeri.</Text>
                  </Stack>
                  <Text>Limbah B3 berbahaya harus ditangani dengan hati-hati dan sesuai dengan aturan dan regulasi yang berlaku. Pengelolaan limbah B3 harus dilakukan oleh perusahaan atau organisasi yang memiliki izin khusus dari pemerintah dan memiliki tenaga ahli yang terlatih dalam penanganan limbah B3. Limbah B3 yang tidak ditangani dengan benar dapat menimbulkan risiko kesehatan dan lingkungan yang serius, termasuk kerusakan tanah, air, dan udara, serta meningkatkan risiko penyakit dan kematian pada manusia dan hewan.</Text>
                </Stack>
              </GridItem>
            </Grid>
          </Stack>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Perizinan</Text>
              <Text fontWeight="bold" fontSize="3xl">Kami</Text>
            </Stack>
          </Flex>
          <Text fontSize="xl" color="#9398b8">Jenis Perizinan</Text>
          <Box mt="10">
            <Accordion allowToggle={true}>
              <Stack spacing="3">
                <AccordionItem>
                  {({isExpanded}) => (
                  <>
                    <AccordionButton bg={isExpanded ? "#273171" : "#bad7e9"} p="4" _hover={{bg: isExpanded ? "#273171" : "#bad7e9"}}>
                      <Box flex="1">
                        <Text fontSize="lg" textAlign="left" fontWeight="bold" color={isExpanded ? "#ffffff" : "#273171"}>Legalitas Perusahaan PT Sarana Patra Jateng (SPJ)</Text>
                      </Box>
                      {
                        isExpanded ? (
                          <FaMinus color={isExpanded ? "#ffffff" : "#273171"} />
                        ):(
                          <FaPlus color={isExpanded ? "#ffffff" : "#273171"} />
                        )
                      }
                    </AccordionButton>
                    <AccordionPanel p="8">
                      <UnorderedList>
                        <ListItem>Akte Pendirian Perseroan Terbatas PT SARANA PATRA JATENG, Tgl 01 Februari 2008, N0. 04</ListItem>
                        <ListItem>NIB NO. 8120212270079 Tgl 07 Desember 2018</ListItem>
                        <ListItem>Izin Usaha NO. 8120212270079 Tgl 25 Februari 2019</ListItem>
                        <ListItem>NPWP NO. 21.028.159.8-081.000</ListItem>
                      </UnorderedList>
                    </AccordionPanel>
                  </>
                )}
                </AccordionItem>

                <AccordionItem>
                  {({isExpanded}) => (
                  <>
                    <AccordionButton bg={isExpanded ? "#273171" : "#bad7e9"} p="4" _hover={{bg: isExpanded ? "#273171" : "#bad7e9"}}>
                      <Box flex="1">
                        <Text fontSize="lg" textAlign="left" fontWeight="bold" color={isExpanded ? "#ffffff" : "#273171"}>Rekomendasi Pengangkutan Limbah B3 KLHK-RI</Text>
                      </Box>
                      {
                        isExpanded ? (
                          <FaMinus color={isExpanded ? "#ffffff" : "#273171"} />
                        ):(
                          <FaPlus color={isExpanded ? "#ffffff" : "#273171"} />
                        )
                      }
                    </AccordionButton>
                    <AccordionPanel p="8">
                      <UnorderedList>
                        <ListItem>NO. S.469/VPLB3/PPLB3/PLB.3/5/2018 Tanggal 7 Mei 2018</ListItem>
                        <ListItem>NO. S.340/VPLB3/PPLB3/PLB.3/3/2020 Tanggal 7 Mei 2020</ListItem>
                      </UnorderedList>
                    </AccordionPanel>
                  </>
                )}
                </AccordionItem>

                <AccordionItem>
                  {({isExpanded}) => (
                  <>
                    <AccordionButton bg={isExpanded ? "#273171" : "#bad7e9"} p="4" _hover={{bg: isExpanded ? "#273171" : "#bad7e9"}}>
                      <Box flex="1">
                        <Text fontSize="lg" textAlign="left" fontWeight="bold" color={isExpanded ? "#ffffff" : "#273171"}>Izin Transportasi Kementrian Perhubungan Dirjen Perhubungan Darat</Text>
                      </Box>
                      {
                        isExpanded ? (
                          <FaMinus color={isExpanded ? "#ffffff" : "#273171"} />
                        ):(
                          <FaPlus color={isExpanded ? "#ffffff" : "#273171"} />
                        )
                      }
                    </AccordionButton>
                    <AccordionPanel p="8">
                      <Text>Keputusan Direktur Jenderal Perhubungan Darat Nomor: SK.00212/AJ.309/1/DJPD/2018 Ditetapkan 25 Agustus 2020</Text>
                    </AccordionPanel>
                  </>
                )}
                </AccordionItem>

                <AccordionItem>
                  {({isExpanded}) => (
                  <>
                    <AccordionButton bg={isExpanded ? "#273171" : "#bad7e9"} p="4" _hover={{bg: isExpanded ? "#273171" : "#bad7e9"}}>
                      <Box flex="1">
                        <Text fontSize="lg" textAlign="left" fontWeight="bold" color={isExpanded ? "#ffffff" : "#273171"}>Asuransi</Text>
                      </Box>
                      {
                        isExpanded ? (
                          <FaMinus color={isExpanded ? "#ffffff" : "#273171"} />
                        ):(
                          <FaPlus color={isExpanded ? "#ffffff" : "#273171"} />
                        )
                      }
                    </AccordionButton>
                    <AccordionPanel p="8">
                      <Text>Policy Number: 1007090822060001 Tanggal 2 Juni 2022 Dikeluarkan Oleh PT ASURANSI UMUM BUMIPUTERA MUDA 1967</Text>
                    </AccordionPanel>
                  </>
                )}
                </AccordionItem>

                <AccordionItem>
                  {({isExpanded}) => (
                  <>
                    <AccordionButton bg={isExpanded ? "#273171" : "#bad7e9"} p="4" _hover={{bg: isExpanded ? "#273171" : "#bad7e9"}}>
                      <Box flex="1">
                        <Text fontSize="lg" textAlign="left" fontWeight="bold" color={isExpanded ? "#ffffff" : "#273171"}>Dukungan BLH Provinsi Jawa Tengah</Text>
                      </Box>
                      {
                        isExpanded ? (
                          <FaMinus color={isExpanded ? "#ffffff" : "#273171"} />
                        ):(
                          <FaPlus color={isExpanded ? "#ffffff" : "#273171"} />
                        )
                      }
                    </AccordionButton>
                    <AccordionPanel p="8">
                      <Text>Dukungan BLH Provinsi Jawa Tengah NO. 660.1/BLH.III/I/0198</Text>
                    </AccordionPanel>
                  </>
                )}
                </AccordionItem>
              </Stack>
            </Accordion>
          </Box>
        </Container>
      </Box>
      {/* <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Kode</Text>
              <Text fontWeight="bold" fontSize="3xl">Limbah</Text>
            </Stack>
          </Flex>
          <Text fontSize="xl" color="#9398b8">Jenis Limbah Yang Diangkut</Text>
          <Grid mt="10" templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr", xl: "auto auto auto auto"}} gap="5">
            {
              data_dummy.kodeLimbah.map((limbah, id) => (
                <CardKodeLimbah key={id} name={limbah.title} title={limbah.name} />
              ))
            }
          </Grid>
        </Container>
      </Box> */}
      <Box py="8">
        <Container maxW="6xl">
          <Grid templateColumns={{base: "1fr", md: "1fr 1fr"}} gap="10">
            <GridItem>
              <Box bg="gray" height="310px" rounded="md" overflow="hidden">
                <ReactPlayer
                width="100%"
                height="100%"
                url="https://www.youtube.com/embed/NTzTB25zyCs" />
              </Box>
            </GridItem>
            <GridItem>
              <Stack spacing="3">
                <Stack>
                  <Text fontWeight="bold" fontSize="lg" color="#fb6a05">Tranporter SPJ</Text>
                  <Text fontSize={{base: "2xl", md: "4xl"}} fontWeight="bold">Solusi untuk mengatasi limbah berbahaya</Text>
                </Stack>
                <Stack>
                  <Text fontSize="sm">Kami menyediakan jasa pengangkutan limbah B3 dan pelayanan lingkungan yang sesuai dengan peraturan yang berlaku, serta dengan cara yang aman dan efisien</Text>
                </Stack>
                <Grid templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}} gap="4">
                  <GridItem>
                    <Text textAlign="left" fontWeight="bold" fontSize={{base:'lg', md:"5xl"}}>44</Text>
                    <Text fontWeight="bold" color="gray">Jenis Limbah B3</Text>
                  </GridItem>
                  <GridItem>
                    <Text textAlign="left" fontWeight="bold" fontSize={{base:'lg', md:"5xl"}}>9</Text>
                    <Text fontWeight="bold" color="gray">Armada Pengangkut</Text>
                  </GridItem>
                  <GridItem>
                    <Text textAlign="left" fontWeight="bold" fontSize={{base:'lg', md:"5xl"}}>3</Text>
                    <Text fontWeight="bold" color="gray">Pengolah Limbah</Text>
                  </GridItem>
                </Grid>
              </Stack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <Box py="8">
        <Box w="full" bg="red">
          <Grid templateColumns={{base: "auto", md: "1fr 450px"}} minH={{h:"450px"}}>
            <GridItem p="5" bg="#273171">
              <Container maxW="2xl">
                <Flex direction="row" gap="5" flexWrap="wrap">
                  <Box w="12" h="1" bg="white" alignSelf="flex" transform="translateY(29px)" />
                  <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
                    <Text fontSize="3xl" fontWeight="thin" color="white">Mengapa</Text>
                    <Text fontWeight="bold" fontSize="3xl" color="white">Memilih Kami</Text>
                  </Stack>
                </Flex>
                <Grid templateColumns={{base: "1fr", md: "1fr 1fr"}} gap="5" mt="10">
                  <GridItem p="5">
                    <Stack direction={{base: "column", md: "row"}} spacing="0.5" gap="5">
                      <Box w={{base:"10", md:"20"}} h="fit-content" rounded="full" transform="translateY(5px)">
                        <FaCoins size="100%" color="white" />
                      </Box>
                      <Stack spacing="0">
                        <Text fontWeight="bold" fontSize="lg" color="white">Pengemudi Terlatih</Text>
                        <Text color="white">Menjalankan Kebijakan (K3) dengan pengemudi terlatih bersertifikat</Text>
                      </Stack>
                    </Stack>
                  </GridItem>
                  <GridItem p="5">
                    <Stack direction={{base: "column", md: "row"}} spacing="0.5" gap="5">
                      <Box w={{base:"10", md:"20"}} h="fit-content" rounded="full" transform="translateY(5px)">
                        <FaCoins size="100%" color="white" />
                      </Box>
                      <Stack spacing="0">
                        <Text fontWeight="bold" fontSize="lg" color="white">Harga Bersaing</Text>
                        <Text color="white">Solusi menyeluruh penanganan pengangkutan limbah B3 dengan efektif dan efisien</Text>
                      </Stack>
                    </Stack>
                  </GridItem>
                  <GridItem p="5">
                    <Stack direction={{base: "column", md: "row"}} spacing="0.5" gap="5">
                      <Box w={{base:"10", md:"20"}} h="fit-content" rounded="full" transform="translateY(5px)">
                        <FaCoins size="100%" color="white" />
                      </Box>
                      <Stack spacing="0">
                        <Text fontWeight="bold" fontSize="lg" color="white">Kualitas Layanan</Text>
                        <Text color="white">Mendukung program pemerintah tentang pemeliharaan lingkungan dan legalitas perijinan</Text>
                      </Stack>
                    </Stack>
                  </GridItem>
                  <GridItem p="5">
                    <Stack direction={{base: "column", md: "row"}} spacing="0.5" gap="5">
                      <Box w={{base:"10", md:"20"}} h="fit-content" rounded="full" transform="translateY(5px)">
                        <FaCoins size="100%" color="white"/>
                      </Box>
                      <Stack spacing="0">
                        <Text fontWeight="bold" fontSize="lg" color="white">Pelayanan Cepat</Text>
                        <Text color="white">Kami melayani pengangkutan 125 jenis limbah B3 sesuai perizinan Kementrian Lingkungan Hidup</Text>
                      </Stack>
                    </Stack>
                  </GridItem>
                </Grid>
              </Container>
            </GridItem>
            <GridItem bg="yellow" display={{base: "none", md: "block"}}>
              <Image src="https://transporter.saranapatra.com/images/whyYouChooseMe.jpg" w="100%" h="100%" objectFit="cover" />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Kerjasama</Text>
              <Text fontWeight="bold" fontSize="3xl">Pengolah Limbah</Text>
            </Stack>
          </Flex>
          <Box mt="10">
            <Grid templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}} gap="5">
              <GridItem p="5" bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Stack>
                  <Image src="https://transporter.saranapatra.com/images/pt-jasa-medivest.png" />
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">Proper Biru</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 14001:2014</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">OSHAS 18001:2007</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 9001:2008</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="5" bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Stack>
                  <Image src="https://transporter.saranapatra.com/images/wastec-international.png" />
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">Proper Biru</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 45991:2018</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 14001:2015</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 9001:2015</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="5" bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Stack>
                  <Image src="https://transporter.saranapatra.com/images/pt.tenang-jaya.png" />
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">Proper Biru</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 9001:2015</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 14001:2015</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 45001:2018</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="5" bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Stack>
                  <Image src="https://transporter.saranapatra.com/images/pt-gen.png" />
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">Proper Biru</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 9001:2015</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 14001:2015</Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <FaCheck color="#273B7E" />
                      <Text fontWeight="bold" color="#273B7E">ISO 45001:2018</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Layanan</Text>
              <Text fontWeight="bold" fontSize="3xl">Kami</Text>
            </Stack>
          </Flex>
          <Text fontSize="xl" color="#9398b8">Jenis Industri</Text>
          <Box mt="10">
            <Grid templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}} gap="5">
              <GridItem bg="whiteAlpha.900" shadow="md" rounded="md" overflow="hidden" _hover={{".child-class": {
                  transform: "scale(1.3)"
                }}}>
                <Stack>
                  <Box overflow="hidden">
                    <Image w="full" src="https://transporter.saranapatra.com/images/medis-dan-kesehatan.jpg" className="child-class" transition="0.3s ease-in-out" />
                  </Box>
                  <Stack p="5">
                    <Text fontWeight="bold" fontSize="xl" textAlign="center">Medis & Kesehatan</Text>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem bg="whiteAlpha.900" shadow="md" rounded="md" overflow="hidden" _hover={{".child-class": {
                  transform: "scale(1.3)"
                }}}>
                <Stack>
                  <Box overflow="hidden">
                    <Image w="full" src="https://transporter.saranapatra.com/images/laporatorium-komersial.jpg" className="child-class" transition="0.3s ease-in-out" />
                  </Box>
                  <Stack p="5">
                    <Text fontWeight="bold" fontSize="xl" textAlign="center">Laboratorium Komersial</Text>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem bg="whiteAlpha.900" shadow="md" rounded="md" overflow="hidden" _hover={{".child-class": {
                  transform: "scale(1.3)"
                }}}>
                <Stack>
                  <Box overflow="hidden">
                    <Image w="full" src="https://transporter.saranapatra.com/images/perusahaan-manufaktur.jpg" className="child-class" transition="0.3s ease-in-out" />
                  </Box>
                  <Stack p="5">
                    <Text fontWeight="bold" fontSize="xl" textAlign="center">Perusahaan Manufaktur</Text>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem bg="whiteAlpha.900" shadow="md" rounded="md" overflow="hidden" _hover={{".child-class": {
                  transform: "scale(1.3)"
                }}}>
                <Stack>
                  <Box overflow="hidden">
                    <Image w="full" src="https://transporter.saranapatra.com/images/pemerintah-institusi.png" className="child-class" transition="0.3s ease-in-out" />
                  </Box>
                  <Stack p="5">
                    <Text fontWeight="bold" fontSize="xl" textAlign="center">Pemerintah & Institusi</Text>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem bg="whiteAlpha.900" shadow="md" rounded="md" overflow="hidden" _hover={{".child-class": {
                  transform: "scale(1.3)"
                }}}>
                <Stack>
                  <Box overflow="hidden">
                    <Image w="full" src="https://transporter.saranapatra.com/images/industri-minyak.jpg" className="child-class" transition="0.3s ease-in-out" />
                  </Box>
                  <Stack p="5">
                    <Text fontWeight="bold" fontSize="xl" textAlign="center">Industri Minyak & Gas</Text>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem bg="whiteAlpha.900" shadow="md" rounded="md" overflow="hidden" _hover={{".child-class": {
                  transform: "scale(1.3)"
                }}}>
                <Stack>
                  <Box overflow="hidden">
                    <Image w="full" src="https://transporter.saranapatra.com/images/agro-kimia.jpg" className="child-class" transition="0.3s ease-in-out" />
                  </Box>
                  <Stack p="5">
                    <Text fontWeight="bold" fontSize="xl" textAlign="center">Agrokimia</Text>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Harga</Text>
              <Text fontWeight="bold" fontSize="3xl">Paket Klinik</Text>
            </Stack>
          </Flex>
          <Box mt="10">
            <Grid templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}} gap="5">
              <GridItem bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Flex direction="column" height="full" justifyContent="space-between">
                  <Stack p="5" gap="5">
                    <Stack alignItems="center">
                      <Text fontWeight="bold" color="#DA251A" fontSize="4xl">Paket A</Text>
                      <Flex direction="row" h="fit-content">
                        <Text fontWeight="bold" color="#DA251A" alignSelf="flex-end" transform="translateY(-5px)">Rp</Text>
                        <Text fontWeight="bold" color="#DA251A" fontSize="4xl">2.500.000</Text>
                      </Flex>
                    </Stack>
                    <Stack spacing="0.5">
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 3 kali pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Maksimal 2 Kg setiap pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 3 yellow bag</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Berita acara pemusnahan/manifest</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Flex h="16" bg="#273171" justifyContent="center" alignItems="center" cursor="pointer" _hover={{'.textButton': {color: "orange"}}}>
                    <Text textAlign="center" fontWeight="bold" color="white" fontSize="lg" transition="0.3s ease-in-out" className="textButton">Pilih Paket</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Flex direction="column" height="full" justifyContent="space-between">
                  <Stack p="5" gap="5">
                    <Stack alignItems="center">
                      <Text fontWeight="bold" color="#DA251A" fontSize="4xl">Paket B</Text>
                      <Flex direction="row" h="fit-content">
                        <Text fontWeight="bold" color="#DA251A" alignSelf="flex-end" transform="translateY(-5px)">Rp</Text>
                        <Text fontWeight="bold" color="#DA251A" fontSize="4xl">3.500.000</Text>
                      </Flex>
                    </Stack>
                    <Stack spacing="0.5">
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 4 kali pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Maksimal 5 Kg setiap pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 5 yellow bag</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Berita acara pemusnahan/manifest</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Flex h="16" bg="#273171" justifyContent="center" alignItems="center" cursor="pointer" _hover={{'.textButton': {color: "orange"}}}>
                    <Text textAlign="center" fontWeight="bold" color="white" fontSize="lg" transition="0.3s ease-in-out" className="textButton">Pilih Paket</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Flex direction="column" height="full" justifyContent="space-between">
                  <Stack p="5" gap="5">
                    <Stack alignItems="center">
                      <Text fontWeight="bold" color="#DA251A" fontSize="4xl">Paket C</Text>
                      <Flex direction="row" h="fit-content">
                        <Text fontWeight="bold" color="#DA251A" alignSelf="flex-end" transform="translateY(-5px)">Rp</Text>
                        <Text fontWeight="bold" color="#DA251A" fontSize="4xl">5.500.000</Text>
                      </Flex>
                    </Stack>
                    <Stack spacing="0.5">
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 3 kali pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Maksimal 7 Kg setiap pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 10 yellow bag</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Berita acara pemusnahan/manifest</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Flex h="16" bg="#273171" justifyContent="center" alignItems="center" cursor="pointer" _hover={{'.textButton': {color: "orange"}}}>
                    <Text textAlign="center" fontWeight="bold" color="white" fontSize="lg" transition="0.3s ease-in-out" className="textButton">Pilih Paket</Text>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
          <Box p="3">
            <OrderedList>
              <ListItem>Kontrak berlaku selama 1 tahun</ListItem>
              <ListItem>Pengambilan di luar paket akan dikenakan biaya tambahan sebagai berikut:
                <UnorderedList>
                  <ListItem>Biaya pengolahan akan dikenakan biaya Rp15.000,-/Kg (sudah termasuk PPN)</ListItem>
                  <ListItem>Biaya 1 kali pengangkutan per lokasi sebesar Rp200.000,-</ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </Box>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Harga</Text>
              <Text fontWeight="bold" fontSize="3xl">Paket Mandiri</Text>
            </Stack>
          </Flex>
          <Box mt="10">
            <Grid templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}} gap="5">
              <GridItem bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Flex direction="column" height="full" justifyContent="space-between">
                  <Stack p="5" gap="5">
                    <Stack alignItems="center">
                      <Text fontWeight="bold" color="#DA251A" fontSize="4xl">Paket A</Text>
                      <Flex direction="row" h="fit-content">
                        <Text fontWeight="bold" color="#DA251A" alignSelf="flex-end" transform="translateY(-5px)">Rp</Text>
                        <Text fontWeight="bold" color="#DA251A" fontSize="4xl">1.550.000</Text>
                      </Flex>
                    </Stack>
                    <Stack spacing="0.5">
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 3 kali pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Maksimal 2 Kg setiap pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Berita acara pemusnahan/manifest</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Flex h="16" bg="#273171" justifyContent="center" alignItems="center" cursor="pointer" _hover={{'.textButton': {color: "orange"}}}>
                    <Text textAlign="center" fontWeight="bold" color="white" fontSize="lg" transition="0.3s ease-in-out" className="textButton">Pilih Paket</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Flex direction="column" height="full" justifyContent="space-between">
                  <Stack p="5" gap="5">
                    <Stack alignItems="center">
                      <Text fontWeight="bold" color="#DA251A" fontSize="4xl">Paket B</Text>
                      <Flex direction="row" h="fit-content">
                        <Text fontWeight="bold" color="#DA251A" alignSelf="flex-end" transform="translateY(-5px)">Rp</Text>
                        <Text fontWeight="bold" color="#DA251A" fontSize="4xl">2.550.000</Text>
                      </Flex>
                    </Stack>
                    <Stack spacing="0.5">
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 4 kali pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Maksimal 5 Kg setiap pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Berita acara pemusnahan/manifest</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Flex h="16" bg="#273171" justifyContent="center" alignItems="center" cursor="pointer" _hover={{'.textButton': {color: "orange"}}}>
                    <Text textAlign="center" fontWeight="bold" color="white" fontSize="lg" transition="0.3s ease-in-out" className="textButton">Pilih Paket</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem bg="whiteAlpha.900" border="solid 1px #adadad" rounded="md" overflow="hidden">
                <Flex direction="column" height="full" justifyContent="space-between">
                  <Stack p="5" gap="5">
                    <Stack alignItems="center">
                      <Text fontWeight="bold" color="#DA251A" fontSize="4xl">Paket C</Text>
                      <Flex direction="row" h="fit-content">
                        <Text fontWeight="bold" color="#DA251A" alignSelf="flex-end" transform="translateY(-5px)">Rp</Text>
                        <Text fontWeight="bold" color="#DA251A" fontSize="4xl">3.500.000</Text>
                      </Flex>
                    </Stack>
                    <Stack spacing="0.5">
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Gratis 3 kali pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Maksimal 7 Kg setiap pengangkutan</Text>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <FaCheck color="#273B7E" />
                        <Text fontWeight="medium" color="#273B7E">Berita acara pemusnahan/manifest</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Flex h="16" bg="#273171" justifyContent="center" alignItems="center" cursor="pointer" _hover={{'.textButton': {color: "orange"}}}>
                    <Text textAlign="center" fontWeight="bold" color="white" fontSize="lg" transition="0.3s ease-in-out" className="textButton">Pilih Paket</Text>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
          <Box p="3">
            <OrderedList>
              <ListItem>Kontrak berlaku selama 1 tahun</ListItem>
              <ListItem>Pengambilan di luar paket akan dikenakan biaya tambahan sebagai berikut:
                <UnorderedList>
                  <ListItem>Biaya pengolahan akan dikenakan biaya Rp12.000,-/Kg (sudah termasuk PPN)</ListItem>
                  <ListItem>Biaya 1 kali pengangkutan per lokasi sebesar Rp150.000,-</ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </Box>
        </Container>
      </Box>
      <Box h="20em" position="relative">
        <Image objectFit="cover" zIndex="-99999px" src="https://transporter.saranapatra.com/images/Clients-Love_img-1.jpeg" position="absolute" top="0" left="0" right="0" width="100%" height="100%" bottom="0" />
        <Box position="absolute" top="0" left="0" right="0" bg="rgba(39, 47, 108, 0.7)" bottom="0" />
        <Container maxW="6xl" position="absolute" top="0" left="0" right="0" bottom="0" py="8">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="white" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="white">Kata</Text>
              <Text fontWeight="bold" fontSize="3xl" color="white">Pelanggan</Text>
            </Stack>
          </Flex>
          <Text fontSize="xl" color="white">Testimonial</Text>
        </Container>
      </Box>
      <Box>
        <Container maxW="6xl" transform="translateY(-190px)">
          <Swiper
            speed="1000"
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: true
            }}
            loop={true}
            modules={[Pagination, Navigation, Autoplay]}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              }
            }}>
            {
              data_dummy.testimonial.data.map((testimoni, id) => (
                <SwiperSlide key={id}>
                  <Box shadow="md" p="5" rounded="md" bg="gray.50" cursor="pointer">
                    <Stack spacing="5">
                      <Stack direction="row">
                        <FaStar color="orange" />
                        <FaStar color="orange" />
                        <FaStar color="orange" />
                        <FaStar color="orange" />
                        <FaStar color="orange" />
                      </Stack>
                      <Text>{testimoni.content}</Text>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack spacing="0.5">
                          <Text fontWeight="bold" fontSize="xl">{testimoni.name}</Text>
                          <Text fontWeight="semibold" fontSize="md" color="gray">{testimoni.instansi}</Text>
                        </Stack>
                        <MdFormatQuote size={50} color="gray" />
                      </Stack>
                    </Stack>
                  </Box>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </Container>
      </Box>
      <Box transform="translateY(-50px)">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Mitra</Text>
              <Text fontWeight="bold" fontSize="3xl">Kami</Text>
            </Stack>
          </Flex>
          <Text fontSize="xl" color="#9398b8">Pengguna Layanan</Text>
          <Box mt="10">
          <Swiper
            pagination={{clickable: true}}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 30
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50
              },
              1068: {
                slidesPerView: 5,
                spaceBetween: 60
              },
            }}>
              <SwiperSlide>
                <Stack spacing="10">
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rs_amino.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rs_fitri_chandra.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/pt_ppis.png" />
                  </Flex>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing="10">
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/bagas_waras.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/ks+.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rs_islam.png" />
                  </Flex>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing="10">
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rs_budi_agung.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/dr_muwardi.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rsjd_surakarta.png" />
                  </Flex>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing="10">
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rs_cakra_husada.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/rs_pku_muhammadiyah.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/umi_barokah.png" />
                  </Flex>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing="10">
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/charoen.png" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/plus_green.jpeg" />
                  </Flex>
                  <Flex h="150px" direction="column" justifyContent="center" alignItems="center">
                    <Image src="https://transporter.saranapatra.com/images/client_kami/yakun.png" />
                  </Flex>
                </Stack>
              </SwiperSlide>
            </Swiper>
          </Box>
        </Container>
      </Box>
      <Box h="20em" position="relative">
        <Image objectFit="cover" zIndex="-99999px" src="https://transporter.saranapatra.com/images/Car-Repairbg_1_.png" position="absolute" top="0" left="0" right="0" width="100%" height="100%" bottom="0" />
        <Container maxW="6xl" position="absolute" top="0" left="0" right="0" bottom="0" py="8">
          <Stack spacing="8" h="full" justifyContent="center">
            <Stack>
              <Text fontWeight="bold" color="white" fontSize="3xl">Hubungi Marketing Kami</Text>
              <Text color="white">Anda tertarik dengan layanan pengangkutan limbah B3 kami?<br />Dapatkan penawaran menarik dengan menghubungi Marketing kami.</Text>
            </Stack>
            <Button transition="0.3s ease-in-out" width="fit-content" py="6" px="12" rounded="full" bg="#EB721F" color="white" _hover={{bg: "#ea7938"}}>Kontak Kami</Button>
          </Stack>
        </Container>
      </Box>
      <Box bg="white" py="8">
        <Container maxW="6xl">
          <Flex direction="row" gap="5" flexWrap="wrap">
            <Box w="12" h="1" bg="#273171" alignSelf="flex" transform="translateY(29px)" />
            <Stack direction={{base: "column", md: "row"}} spacing="0" gap="1">
              <Text fontSize="3xl" fontWeight="thin" color="#273171">Galeri</Text>
              <Text fontWeight="bold" fontSize="3xl">Transporter</Text>
            </Stack>
          </Flex>
          <Text fontSize="xl" color="#9398b8">Foto Dokumentasi</Text>
          <Box mt="10">
            <Stack justifyContent="center" alignItems="center" spacing="12">
              <Stack direction="row">
                <Box onClick={() => onChangeGalery('all')} py="2" px="4" bg={(selectedGalery.toLowerCase() === 'all') ? 'gray.300' : 'gray.600'} width="fit-content" rounded="md" cursor="pointer">
                  <Text color="white" fontWeight="bold">ALL</Text>
                </Box>
                {
                  data_dummy.galeri_transporter.types.map((galeri, key) => (
                    <Box key={key} onClick={() => onChangeGalery(galeri.type)} py="2" px="4" bg={(selectedGalery.toLowerCase() === galeri.type.toLowerCase()) ? 'gray.300' : 'gray.600'} width="fit-content" rounded="md" cursor="pointer">
                      <Text color="white" fontWeight="bold">{galeri.type}</Text>
                    </Box>
                  ))
                }
              </Stack>
                <Grid templateColumns={{base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}} gap="5">
                  {
                    dataFiltered.map((galeri, key) => (
                      <GridItem key={key} height="200px">
                        <Image src={galeri.name} w="100%" h="100%" objectFit="cover" />
                      </GridItem>
                    ))
                  }
                </Grid>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box w="full" bg="rgba(210, 210, 210, 0.3)" position="relative" py="16">
        <Image
          src="https://transporter.saranapatra.com/images/imgLimbah05.jpg"
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          zIndex="-9"
          objectFit="cover" />
        <Container maxW="6xl">
          <Box w="full" bg="white" p="10">
            <Grid templateColumns={{ base: "1fr", lg: "auto 1fr" }} gap={{ base: "10", md: "10", lg: "0.5" }}>
              <GridItem>
                <Stack spacing="8">
                  <Stack>
                    <Stack direction={{ base: "column", md: "row" }} spacing="1.5">
                      <Text fontSize={{ base: "3xl", xl: "4xl" }} fontWeight="bold">HUBUNGI</Text>
                      <Text fontSize={{ base: "3xl", xl: "4xl" }} fontWeight="bold" color="orange">KAMI</Text>
                    </Stack>
                  </Stack>
                  <Stack w={{ lg: "96" }} fontSize="md">
                    <Text>Dapatkan konsultasi dan penawaran menarik dari kami dengan mengisi data Anda di form. Tim kami akan segera menghubungi Anda.</Text>
                  </Stack>
                  <Stack spacing="5" alignItems="flex-start">
                    <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                      <Flex w="40px" h="40px" bg="orange" rounded="full" justifyContent="center" alignItems="center">
                        <FiMail color="white" size="25" />
                      </Flex>
                      <Text fontWeight="bold">spj@saranapatra.com</Text>
                    </Stack>
                    <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                      <Flex w="40px" h="40px" bg="orange" rounded="full" justifyContent="center" alignItems="center">
                        <FaPhoneVolume color="white" size="25" />
                      </Flex>
                      <Text fontWeight="bold">024 - 76630507</Text>
                    </Stack>
                    <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                      <Flex w="40px" h="40px" bg="orange" rounded="full" justifyContent="center" alignItems="center">
                        <FaGlobe color="white" size="25" />
                      </Flex>
                      <Text fontWeight="bold">http://saranapatra.com</Text>
                    </Stack>
                    <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                      <Flex w="40px" h="40px" bg="orange" rounded="full" justifyContent="center" alignItems="center">
                        <FaMapMarkerAlt color="white" size="25" />
                      </Flex>
                      <Stack spacing="0.5">
                        <Text fontWeight="bold">PT. Sarana Patra Jateng</Text>
                        <Text>Jl. Pamularsih Raya No.58 Semarang</Text>
                      </Stack>
                    </Stack>
                    <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                      <Flex w="40px" h="40px" bg="orange" rounded="full" justifyContent="center" alignItems="center">
                        <AiOutlineClockCircle color="white" size="25" />
                      </Flex>
                      <Stack spacing="0.5">
                        <Text fontWeight="bold">Jam Kerja</Text>
                        <Text>Senin - Jumat 08:00 - 17:00</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem>
                <Stack spacing="5">
                  <Grid gap="3" templateColumns={{ base: "1fr", md: "1fr auto" }}>
                    <GridItem>
                      <Stack>
                        <Stack direction="row">
                          <Text fontWeight="bold">Nama Lengkap</Text>
                          <Text color="red">*</Text>
                        </Stack>
                        <Input placeholder="Nama Lengkap" value={namaLengkap.value} onChange={namaLengkap.onChange} />
                      </Stack>
                    </GridItem>
                    <GridItem>
                      <Stack>
                        <Text fontWeight="bold">Tanggal Lahir Anda</Text>
                        <Input placeholder="Tanggal Lahir Anda" type="date" value={tglLahir.value} onChange={tglLahir.onChange} />
                      </Stack>
                    </GridItem>
                  </Grid>
                  <Stack>
                    <Stack direction="row">
                      <Text fontWeight="bold">Perusahaan</Text>
                    </Stack>
                    <Input placeholder="Nama Perusahaan" value={perusahaan.value} onChange={perusahaan.onChange} />
                  </Stack>
                  <Stack>
                    <Stack direction="row">
                      <Text fontWeight="bold">Alamat</Text>
                    </Stack>
                    <Input placeholder="Alamat Rumah/Kantor" value={alamat.value} onChange={alamat.onChange} />
                  </Stack>
                  <Grid gap="3" templateColumns={{ base: "1fr", md: "1fr 1fr" }}>
                    <GridItem>
                      <Stack>
                        <Stack direction="row">
                          <Text fontWeight="bold">Email</Text>
                          <Text color="red">*</Text>
                        </Stack>
                        <Input placeholder="Alamat Email" value={email.value} onChange={email.onChange} />
                      </Stack>
                    </GridItem>
                    <GridItem>
                      <Stack>
                        <Text fontWeight="bold">No. Handphone (Whatsapp)</Text>
                        <Input placeholder="No. HP/WA Anda" value={noHandphone.value} onChange={noHandphone.onChange} />
                      </Stack>
                    </GridItem>
                  </Grid>
                  <Stack>
                    <Text fontWeight="bold">Jenis Limbah</Text>
                    <Select onChange={handleSelectChange}>
                      {/* {
                        data_dummy.ourService.map((product, id) => (
                          <option key={id}>{product.name}</option>
                        ))
                      } */}
                        <option value="">- Jenis Limbah -</option>
                        <option value="Limbah B3 Padat">Limbah B3 Padat</option>
                        <option value="Limbah B3 Cair">Limbah B3 Cair</option>
                        <option value="Limbah B3 Residu">Limbah B3 Residu</option>
                    </Select>
                  </Stack>
                  <Stack>
                    <Stack direction="row">
                      <Text fontWeight="bold">Pesan</Text>
                      <Text color="red">*</Text>
                    </Stack>
                    <Textarea placeholder="Masukkan Pesanan Anda" value={pesan.value} onChange={pesan.onChange} />
                  </Stack>
                  <Stack>
                    <Button w="fit-content" bg="blue.400" color="white" onClick={sendEmail}>Submit Form</Button>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box onClick={() => {window.open('https://wa.me/089616099449', '_blank')}} cursor="pointer" p="3" rounded="full" bg="#25D366" position="fixed" bottom="10" right="10">
        <FaWhatsapp size="35" color="white" fontWeight="bold" />
      </Box>
    </>
  );
}

export default App;
