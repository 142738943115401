import { useState } from "react"

export const useInput = (defaultValue) => {
    const [state, setState] = useState(defaultValue)

    const onChangeHandle = (e) => {
        setState(e.target.value)
    }

    const onResetHandle = (e) => {
        setState('')
    }

    return {
        value: state,
        onChange: onChangeHandle,
        onReset: onResetHandle
    }
}